/*
 * Copyright 2023 by Avid Technology, Inc.
 */

export const loginTypes = {
    DEFAULT: 'default',
    KERBEROS: 'kerberos',
    OKTA: 'okta',
    OPEN_ID: 'openId',
    OPEN_ID_ISSUER: 'openIdIssuer',
};
