/*
 * Copyright 2021-2022, 2023-2024 by Avid Technology, Inc.
 */

import User from './AV.User';
import LayoutManager from '../layout-manager/AV.LayoutManager';
import initStore from '../../systemsStore/initStore';
import { AV } from './av';
import { Logger } from '../../../../logger/logger';
import KeyNavigationHandler from './KeyNavigationHandler';
import ModalsManager from './AV.ModalsManager';
import FloatManager from '../../float/AV.FloatManager';
import floatStorage from '../../float/FloatStorage';
import MSChatProvider from '../ms-chat-provider/MSChatProvider';
import { layoutStorage, sortLayoutOrderStorage } from '../layout-manager/LayoutStorage';

export const initMSChatProvider = (initInfo) => {
    const { msEntraApplicationId = '' } = initInfo;

    window.AV.internal.msEntraApplicationId = msEntraApplicationId;

    AV.MSChatProvider = new MSChatProvider(msEntraApplicationId);

    return AV.MSChatProvider.init();
};

export const initAVUser = ({
    username,
    email,
    isAdministrator,
    isAuthorized,
}) => {
    const {
        internal: {
            userSettings: {
                locale,
                searchQueryMode,
                timeZone,
            },
        },
    } = AV;

    AV.User = new User({
        name: username,
        email,
        isAdministrator,
        isAuthorized,
        locale,
        searchQueryMode,
        timeZone,
    });
};

export const initAVFloatManager = () => {
    AV.FloatManager = new FloatManager(floatStorage);

    return Promise.resolve();
};

export const initAVLayoutManager = (initInfo) => {
    const { defaultLayout: { body } } = initInfo;

    AV.LayoutManager = new LayoutManager({ body }, { layoutStorage, sortLayoutOrderStorage });

    return Promise.resolve();
};

export const initAVBuildVersion = ({ version, build, releaseYear }) => {
    if (!window.AV) {
        window.AV = {};
    }

    window.AV.internal = window.AV.internal || {};
    window.AV.internal.version = version;
    window.AV.internal.build = build;
    window.AV.internal.releaseYear = releaseYear;
};

export const initSystemsStore = () => initStore().then(({ systems, serviceRoots }) => {
    AV.Systems = systems;
    AV.ServiceRoots = serviceRoots;
});

export const setCustomerProfile = (profile) => {
    AV.internal.profile = profile;
};

export const saveIamTokenData = (iamToken) => {
    if (!window.AV) {
        window.AV = {};
    }

    window.AV.internal = window.AV.internal || {};
    window.AV.internal.masterRegion = iamToken && iamToken.masterRegion;
    window.AV.internal.user = {};

    Object.defineProperties(window.AV.internal.user, {
        contextId: {
            writable: false,
            value: iamToken && iamToken.contextId,
        },
        identityId: {
            writable: false,
            value: iamToken && iamToken.identityId,
        },
    });
};

export const initLogger = () => {
    AV.Logger = Logger;
};

export const initKeyNavigationHandler = () => {
    AV.KeyNavigation = new KeyNavigationHandler();
};

export const initModalsManager = () => {
    AV.ModalsManager = new ModalsManager();
};
