/*
 * Copyright 2018, 2021-2023 by Avid Technology, Inc.
 */

function getElementWithClassName(elementName, className) {
    const element = document.createElement(elementName);

    element.className = className;

    return element;
}

function getParagraphWithText(text) {
    const paragraph = getElementWithClassName('p', '');

    paragraph.innerText = text;

    return paragraph;
}

function onReloadButtonClick() {
    window.location.reload();
}

function getReloadButton() {
    const button = getElementWithClassName('button', 'fatal-error-reload-button');

    button.innerText = AV.LoginLocalization['reload-button-label'];
    button.onclick = onReloadButtonClick;

    return button;
}

function getDefaultContainerStyles() {
    return 'width:800px;height:316px;display:flex;align-items:center;flex-wrap:nowrap;flex-direction:column;'
        + 'justify-content:flex-start;position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;color:white;';
}

function getContainer() {
    const container = getElementWithClassName('div', 'fatal-error-container');

    container.setAttribute('style', getDefaultContainerStyles());

    return container;
}

function getBaseElement() {
    return {
        wrap: getElementWithClassName('div', 'fatal-error-wrapper'),
        logo: getElementWithClassName('div', 'logo'),
        container: getContainer(),
        title: getElementWithClassName('div', 'fatal-error-title'),
    };
}

const getAccessDeniedErrorDom = (errorText) => {
    const {
        wrap,
        logo,
        container,
        title,
    } = getBaseElement();

    container.appendChild(title);
    container.appendChild(getParagraphWithText(errorText));
    wrap.appendChild(logo);
    wrap.appendChild(container);

    return wrap;
};

const getFatalErrorDom = (errorText) => {
    const {
        wrap,
        logo,
        container,
        title,
    } = getBaseElement();

    container.appendChild(title);
    container.appendChild(getParagraphWithText(errorText));
    container.appendChild(getParagraphWithText(AV.LoginLocalization['reload-page-message']));
    container.appendChild(getReloadButton());
    wrap.appendChild(logo);
    wrap.appendChild(container);

    return wrap;
};

export {
    getFatalErrorDom,
    getAccessDeniedErrorDom,
};
