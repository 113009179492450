/*
* Copyright 2024 by Avid Technology, Inc.
*/
import { Providers } from '@microsoft/mgt-element';
import logger from '../../../../../logger/logger';
import prepareIdsToRemove from './prepareIdsToRemoveSubscription';

const removeActiveSubscriptions = async (ids) => {
    const client = Providers.globalProvider?.graph?.client;

    if (client) {
        try {
            const { value } = await client.api('/subscriptions').get();

            const idsToRemove = prepareIdsToRemove(ids, value);
            const subscriptionsToDelete = idsToRemove
                .map(
                    (id) => client
                        .api(`/subscriptions/${id}`)
                        .delete(),
                );

            await Promise.all(subscriptionsToDelete);
        } catch (error) {
            logger.error('[MSChatProvider] Failed to remove subscriptions', error);
        }
    }
};

export default removeActiveSubscriptions;
