/*
 * Copyright 2018, 2020-2023 by Avid Technology, Inc.
 */

import { getModes, getFallbackProperty } from './getFallbackProperty';
import { AV } from '../av';
import logger from '../../../../../logger/logger';

let properties;
let modes;
let mode;
let initialized = false;

function getValue(key) {
    return getFallbackProperty(properties, modes, mode, key);
}

AV.SystemProperties = {
    /**
     * Resets system properties.
     * @private
     */
    _reset: () => {
        properties = null;
        initialized = false;
    },

    /**
     * Returns system properties value.
     * @param {String} key System properties key
     * @param {*} [def] Default value
     * @return {undefined|Boolean|Number|Array|Object|String}
     */
    get(key, def) {
        if (!initialized) {
            logger.warn('[System properties] Properties are not initialized.');
            return def;
        }
        const value = getValue(key);
        if (typeof value !== 'undefined' && value !== null) {
            return value;
        }
        return def;
    },

    init: (modeFeatureToggles) => {
        if (!properties) {
            properties = modeFeatureToggles;
        }
        initialized = true;
        if (!AV.internal.mode) {
            mode = 'default';
        } else {
            mode = AV.internal.embeddedIn
                ? `${AV.internal.mode}_${AV.internal.embeddedIn}`
                : AV.internal.mode;
        }
        modes = getModes(properties, mode);
    },

    isInitialized: () => initialized,

    /**
     * Get raw value of system properties
     * @param {String} key System properties key
     * @return {String|undefined}
     * @deprecated since version 2018.1
     * @see {@link get}
     */
    getRawValue(key) {
        if (!initialized) {
            return;
        }
        return getValue(key);
    },

    /**
     * Get value converted to boolean
     * @param {String} key  System properties key
     * @return {boolean}
     * @deprecated since version 2018.1
     * @see {@link get}
     */
    getBoolean(key) {
        if (!initialized) {
            return;
        }
        const value = getValue(key);
        return !((!value || value === 'false'));
    },

    /**
     * Get value as string.
     * Undefined value will be returned as default value.
     * @param {string} key System property key.
     * @param {string} def Default value. If not specified then empty string.
     * @return {string}
     * @deprecated since version 2018.1
     * @see {@link get}
     */
    getString(key, def) {
        if (def === undefined) {
            def = '';
        }
        if (!initialized) {
            return def;
        }
        return getValue(key) || def;
    },
};
