/*
 * Copyright 2024 by Avid Technology, Inc.
 */
const getExpirationDateTime = () => {
    const date = new Date();
    // 59 minutes from now
    date.setMinutes(date.getMinutes() + 59);

    return date.toISOString();
};

export default getExpirationDateTime;
