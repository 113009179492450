/*
 * Copyright 2023 by Avid Technology, Inc.
 */

class Observer {
    constructor() {
        this.observers = new Set();
    }

    subscribe(observer) {
        this.observers.add(observer);
    }

    unsubscribe(observer) {
        this.observers.delete(observer);
    }

    update(data) {
        this.observers.forEach((observer) => {
            observer(data);
        });
    }
}

export default Observer;
