/*
 * Copyright 2023 by Avid Technology, Inc.
 */

export default class KeyNavigationHandler {
    constructor() {
        this.keyListenersMap = new Map();
    }

    assignToKeyListenersMap = (arrayWithKeysAndFunctions) => {
        arrayWithKeysAndFunctions.forEach((array) => {
            const [key, func] = array;

            this.keyListenersMap.set(key, func);
        });

        return this.keyListenersMap;
    };

    keyDownListener = (event, arrayOfKeysAndFunctions) => {
        const { code } = event;
        const listenerMap = this.assignToKeyListenersMap(arrayOfKeysAndFunctions);
        const listener = listenerMap.get(code);

        return listener && listener(event);
    };

    tabKeyHandler = (event, focusableElements) => {
        event.preventDefault();

        const total = focusableElements.length;
        let elementIndex = focusableElements.indexOf(event.target);

        if (event.shiftKey) {
            if (elementIndex - 1 < 0) {
                elementIndex = total - 1;
            } else {
                elementIndex -= 1;
            }

            focusableElements[elementIndex].focus();

            return;
        }

        if (elementIndex + 1 === total) {
            elementIndex = 0;
        } else {
            elementIndex += 1;
        }

        focusableElements[elementIndex].focus();
    };

    enterKeyHandler = (event, confirmCallback, cancelCallback, cancelSelector) => {
        if (event.target === cancelSelector) {
            cancelCallback();

            return;
        }

        confirmCallback();
    };

    spaceKeyHandler = (event, arrayOfInputs, arrayOfCheckboxesAndActions) => {
        let isInputField = false;

        arrayOfInputs.forEach((item) => {
            if (event.target === item) {
                isInputField = true;
            }
        });

        if (isInputField) {
            return;
        }

        event.preventDefault();

        arrayOfCheckboxesAndActions.forEach((item) => {
            const [checkboxSelector, action] = item;

            if (event.target === checkboxSelector) {
                action();
            }
        });
    };
}
