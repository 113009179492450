/*
 * Copyright 2021, 2023 by Avid Technology, Inc.
 */

import { v4 as uuid } from 'uuid';
import styles from './styles/login-tpl.scss';
import createStylesElement from './createStylesElement';
import { brandingProfiles } from '../../constants';

const getLoginBackground = () => {
    const isMcemProfile = window.AV.internal.profile === brandingProfiles.mcem;
    const background = createStylesElement({
        classNames: [styles['login-background-cnt']],
    });

    const picture = isMcemProfile ? styles['mcem-background'] : styles['default-background'];
    const loginBgLeft = createStylesElement({
        classNames: [styles['login-background-left'], picture],
    });
    const overlay = isMcemProfile ? styles['mcem-overlay'] : styles['default-overlay'];
    const loginBgRight = createStylesElement({
        classNames: [styles['login-background-right'], overlay],
    });

    background.appendChild(loginBgLeft);
    background.appendChild(loginBgRight);

    return background;
};

const getTitle = () => {
    const isMcemProfile = window.AV.internal.profile === brandingProfiles.mcem;
    const profileTitle = isMcemProfile ? styles['mcem-title'] : styles['default-title'];
    return createStylesElement({
        classNames: ['title', profileTitle, styles.title],
        id: `title-${uuid()}`,
    });
};

const createLoginArea = () => {
    const isMcemProfile = window.AV.internal.profile === brandingProfiles.mcem;
    const areaStyle = [styles['mcux-area']];
    if (isMcemProfile) {
        areaStyle.push(styles['mcux-mcem-area']);
    }
    return createStylesElement({
        classNames: ['mcux-area', ...areaStyle],
    });
};

export {
    getLoginBackground,
    getTitle,
    createLoginArea,
};
