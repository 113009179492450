/*
 * Copyright 2018, 2021, 2022-2023 by Avid Technology, Inc.
 */

import eulaFormView from '../form/eula-form';
import saveEula from '../data-provider/save-eula';
import getLicenseText from '../data-provider/get-license-text';
import logger from '../../../../logger/logger';
import keyCodes from '../../keyCodes';

const eulaManager = {
    initEulaForm(extendedInitInfo) {
        eulaFormView.render();
        return new Promise((resolve) => getLicenseText()
            .then((text) => {
                eulaFormView.show();
                eulaFormView.addAgreementText(text || '');

                eulaFormView.onSubmit((event) => {
                    event.preventDefault();
                    eulaFormView.showLoading();
                    return eulaManager.saveEulaHandler(resolve, extendedInitInfo);
                });
                eulaManager.addGlobalEvents();
            })
            .catch((err) => {
                logger.warn('[EULA] Eula page initialize failed.', err);
                eulaFormView.renderRequestError();
            }));
    },

    saveEulaHandler(resolve, extendedInitInfo) {
        return saveEula()
            .then(() => {
                eulaFormView.destroy();
                eulaFormView.renderSpinner();
                eulaManager.removeGlobalEvents();
                resolve(extendedInitInfo);
            })
            .catch(eulaFormView.renderRequestError);
    },

    addGlobalEvents() {
        document.addEventListener('keydown', (event) => {
            eulaManager.globalKeyDownHandler(event);
        });
    },

    removeGlobalEvents() {
        document.removeEventListener('keydown', (event) => {
            eulaManager.globalKeyDownHandler(event);
        });
    },

    globalKeyDownHandler(event) {
        const { altKey, code } = event;

        if (altKey && code === keyCodes.ARROW_LEFT) {
            event.preventDefault();

            AV.LoginProvider.endSession()
                .then(() => {
                    location.reload();
                }).catch((err) => {
                    logger.warn('[Eula page] Logout was failed! Err: ', err);
                    location.reload();
                });
        }
    },
};

export default eulaManager;
