/*
 * Copyright 2017, 2022-2023 by Avid Technology, Inc.
 */

import { get } from './http-requests';
import { filterBrowsableSystems } from './utils';
import config from './config';

/**
 * Returns resource item filtered provided system.
 * @param {Array} resourceSystems List of systems for which the resource is applicable
 * @param {Object} system System.
 * @param {Object} resource Resource.
 * @param {String} resourceName Resource name.
 * @returns {Object} Resource item.
 */
function getResourceItem(resourceSystems, system, resource, resourceName) {
    const result = {};

    if (resourceSystems) {
        resourceSystems.forEach((loc) => {
            if (loc.systemType === system.systemType && loc.systemID === system.systemID) {
                const { systems: _, ...obj } = resource; // NOSONAR

                result[resourceName] = obj;
            }
        });
    }

    return result;
}

/**
 * Returns resource items.
 * @param {Object} resource Resource.
 * @param {String} resourceName Resource name.
 * @param {Object} system System.
 * @returns {Object} Resource items.
 */
function getResource(resource, resourceName, system) {
    const result = {};

    resource.forEach((resourceItem) => {
        const resourceSystems = resourceItem.systems;
        const links = getResourceItem(resourceSystems, system, resourceItem, resourceName);

        if (links) {
            Object.assign(result, links);
        }
    });

    return result;
}

/**
 * Returns resources for specified system.
 * @param {Object} resources Resources from the response.
 * @param {Object} system System.
 * @returns {Object} List of resources which can be applicable for system.
 */
function getSystemResources(resources, system) {
    const result = {};

    Object.entries(resources).forEach(([resourceName, resource]) => {
        const resultResources = getResource(resource, resourceName, system);

        Object.assign(result, resultResources);
    });

    return result;
}

/**
 * Registry class.
 * Class Registry provides base API for CTMS Registry.
 * getServiceRoots - returns raw service root call.
 * getSystems - returns list of systems with links for all available resources.
 * getBrowsableSystems - returns list of browsable systems with links for all available resources.
 */
class Registry {
    constructor() {
        this.serviceRoots = config.serviceRoots;
    }

    /**
     * Returns service roots.
     * @param forceRefresh To force receiving data from backend (not from cached version)
     * @param {Object} queryParams Object that contains query params (key: value)
     * @returns {Promise} raw response.
     */
    getServiceRoots({ forceRefresh } = {}, queryParams = {}) {
        const rootsFromCache = window.AV.ServiceRoots && window.AV.ServiceRoots.get();
        const isCacheDefined = rootsFromCache && rootsFromCache.systems;

        if (isCacheDefined && !forceRefresh) {
            return Promise.resolve(rootsFromCache);
        }

        return get(this.serviceRoots, queryParams);
    }

    /**
     * Returns systems with existing links from resources.
     * @returns {Promise} Available systems.
     */
    getSystems() {
        return this.getServiceRoots().then((response) => this.extractSystems(response));
    }

    /**
     * Extract data about systems with corresponding links from resources
     * @param {Object} serviceRoots /serviceroots call response data
     * @returns {Array<Object>} Available systems.
     */
    extractSystems(serviceRoots) {
        const systems = serviceRoots.systems || [];
        const { resources } = serviceRoots;

        return systems.map((system) => ({
            ...system,
            _links: getSystemResources(resources, system),
        }));
    }

    /**
     * Returns browsable systems.
     * System is not available for browsing if there is no this system
     * in both loc:locations and loc:root-item systems in resources.
     * @param forceRefresh To force receiving data from backend (not from cached version).
     * @param {Object} queryParams Object that contains query params (key: value),
     * for getting response with already filtered systems from backend by defined params.
     * @returns {Promise} Available filtered systems.
     */
    getBrowsableSystems({ forceRefresh } = {}, queryParams = {}) {
        return this.getServiceRoots({ forceRefresh }, queryParams).then((response) => {
            const systems = response.systems || [];
            const { resources } = response;
            const availableSystems = systems
                .filter((system) => filterBrowsableSystems(resources, system));

            return availableSystems.map((system) => ({
                ...system,
                _links: getSystemResources(resources, system),
            }));
        });
    }
}

export default new Registry();
