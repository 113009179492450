/*
 * Copyright 2021-2023 by Avid Technology, Inc.
 */

import {
    createKerberosButton,
    createSsoArea,
} from '../view/sso-area';
import {
    el,
    hide,
    setContent,
    setInnerText,
    triggerAnimation,
} from './dom-utils';
import { createLanguageSelector } from '../view/language-selector/languageSelector';
import createOktaArea from '../view/okta-area';
import loginTpl from '../view/login-tpl';
import { loginTypes } from '../constants';

export default class LoginForm {
    constructor({
        defaultProvider,
        isSsoWindowsEnabled,
        oktaIssuer,
        isOpenIdEnabled,
        openIdIssuer,
        isSsoOktaEnabled,
        validateData,
        onLogin,
        onLanguageChange,
    }) {
        this.defaultProvider = defaultProvider;
        this.isSsoOktaEnabled = isSsoOktaEnabled;
        this.oktaIssuer = oktaIssuer;
        this.isOpenIdEnabled = isOpenIdEnabled;
        this.openIdIssuer = openIdIssuer;
        this.isSsoWindowsEnabled = isSsoWindowsEnabled;
        this.validateData = validateData;
        this.onLoginCallback = onLogin;
        this.onLanguageChange = onLanguageChange;

        this.onSubmit = this.onSubmit.bind(this);
        this.onKerberosLogin = this.onKerberosLogin.bind(this);
        this.onOktaLogin = this.onOktaLogin.bind(this);
        this.onOpenIdLogin = this.onOpenIdLogin.bind(this);
    }

    render(isAdmin) {
        this.destroy();

        const {
            container,
            loginControls,
            getters,
        } = loginTpl(isAdmin, this.isSsoOktaEnabled, this.isOpenIdEnabled, this.defaultProvider);
        const isSsoOktaDefaultProvider = this.isSsoOktaEnabled === this.defaultProvider;
        const isOpenIdDefaultProvider = this.isOpenIdEnabled === this.defaultProvider;

        this.containerElem = container;
        this.getters = getters;

        document.body.append(container);

        if (this.isSsoWindowsEnabled) {
            const ssoArea = createSsoArea();

            if (this.isSsoWindowsEnabled) {
                this.kerberosButton = createKerberosButton({ onLogin: this.onKerberosLogin });
                ssoArea.append(this.kerberosButton.getElement());
            }

            loginControls.append(ssoArea.getElement());
        }

        if (!isAdmin) {
            this.languageSelector = createLanguageSelector(this.onLanguageChange);
            loginControls.appendChild(this.languageSelector.getElement());
        }

        createOktaArea({
            isAdmin,
            getters,
            isOpenIdDefaultProvider,
            isSsoOktaDefaultProvider,
            isOpenIdEnabled: this.isOpenIdEnabled,
            openIdIssuer: this.openIdIssuer,
            isSsoOktaEnabled: this.isSsoOktaEnabled,
            oktaIssuer: this.oktaIssuer,
            showRequestMessage: this.showRequestMessage,
            showRequestError: this.showRequestError,
            onOpenIdLogin: this.onOpenIdLogin,
            onOktaLogin: this.onOktaLogin,
            onSubmit: this.onSubmit,
        });

        if (!this.isOpenIdEnabled && !this.isSsoOktaEnabled) {
            this.getters.form().addEventListener('submit', this.onSubmit);
            this.getters.nameInput().focus();
        }
    }

    destroy() {
        if (!this.containerElem) {
            document.body.innerHTML = '';
            return;
        }

        if (this.getters.form()) {
            this.getters.form().removeEventListener('submit', this.onSubmit);
        }

        if (this.getters.oktaButton()) {
            this.getters.oktaButton().removeEventListener('click', this.onOktaLogin);
        }

        if (this.getters.openIdButton()) {
            this.getters.openIdButton().removeEventListener('click', this.onOpenIdLogin);
        }

        if (this.languageSelector) {
            this.languageSelector.destroy();
            this.languageSelector = null;
        }

        if (this.kerberosButton) {
            this.kerberosButton.destroy();
            this.kerberosButton = null;
        }

        this.containerElem.remove();
        this.containerElem = null;

        document.body.innerHTML = '';
    }

    onSubmit(event) {
        event.preventDefault();
        this.cleanError();
        const formData = {
            username: this.getName(),
            password: this.getPassword(),
        };
        const result = this.validateData(formData);
        if (!result.isValid) {
            this.showError(result.message);
            this.getters.nameInput().focus();
            return;
        }
        this.onLoginCallback(loginTypes.DEFAULT, formData);
    }

    onKerberosLogin() {
        this.onLoginCallback(loginTypes.KERBEROS);
    }

    onOktaLogin(event) {
        if (event) event.preventDefault();
        this.onLoginCallback(loginTypes.OKTA);
    }

    onOpenIdLogin(event) {
        if (event) event.preventDefault();
        this.onLoginCallback(loginTypes.OPEN_ID);
    }

    focusLoginControlForType(type) {
        if (type === loginTypes.DEFAULT) {
            this.getters.nameInput().focus();
        } else if (type === loginTypes.KERBEROS) {
            this.kerberosButton.focus();
        } else if (type === loginTypes.OKTA) {
            this.getters.oktaButton().focus();
        } else if (type === loginTypes.OPEN_ID) {
            this.getters.openIdButton().focus();
        }
    }

    focusSelectLanguageButton() {
        this.languageSelector.focus();
    }

    showRequestError() {
        const msg = AV.LoginLocalization['page-loading-error'];
        setInnerText(el('mcux-login-loading-inner'), msg);
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-controls').style.visibility = 'hidden';
    }

    showLoadingMessage() {
        const msg = '...';
        setInnerText(el('mcux-login-loading-inner'), msg);
        el('mcux-login-loading-outer').style.display = 'flex';
        el('mcux-login-controls').style.visibility = 'hidden';
    }

    cleanError() {
        hide(el('mcux-login-error'));
    }

    showError(str) {
        const errEl = el('mcux-login-error');
        setContent(errEl, str);
        triggerAnimation(errEl);
    }

    showRequestMessage(msg) {
        this.show();
        if (msg) {
            this.showError(msg);
        } else {
            this.hideLoading();
            this.showError();
        }
    }

    setBrowserWarning(browserWarning) {
        setInnerText(el('mcux-login-browser-message'), browserWarning);
        el('mcux-login-browser-notice').style.display = 'block';
    }

    hideLoading() {
        el('mcux-login-loading-outer').style.display = 'none';
    }

    show() {
        el('mcux-login-controls').style.visibility = 'visible';
    }

    getName() {
        return this.getters.nameInput().value;
    }

    getPassword() {
        return el('password').value;
    }

    renderSpinner() {
        const spinner = document.createElement('div');
        spinner.setAttribute('id', 'avid-nux-page-load-indicator');
        spinner.setAttribute('class', 'nux-loading-spinner-large');
        document.body.appendChild(spinner);
    }
}
