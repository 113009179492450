/*
 * Copyright 2021-2023 by Avid Technology, Inc.
 */

import getEmbeddedInMode from './getEmbeddedInMode';

const INIT_CONFIG_LINK = '/avid-ui-initialization/v1/configuration';

function status(response) {
    if ((response.status >= 200 && response.status < 300) || response.ok) {
        return Promise.resolve(response.json());
    }

    return Promise.reject(response);
}

export default () => {
    const mode = getEmbeddedInMode();
    const urlParams = mode
        ? Object.entries(mode).map(([key, value]) => `${key}=${value}`).join('&')
        : 'mode=main';

    return fetch(`${INIT_CONFIG_LINK}?${urlParams}`, {
        headers: {
            Accept: 'application/json',
        },
        credentials: 'same-origin',
    })
        .then(status);
};
