/*
 * Copyright 2018-2024 by Avid Technology, Inc.
 */

import logoutCall from './logout-call';
import getHashFromURL from '../../util/getHashFromURL';
import { currentLayoutDataKey } from '../../initialization-flow/layout-manager/sessionStorageKeys';
import { floatKey } from '../../float/FloatStorage';
import { SIGN_OUT_KEY, clipboardKey } from '../../constants';
import logger from '../../../../logger/logger';

if (!window.AV) {
    window.AV = {};
}

window.AV.LoginProvider = window.AV.LoginProvider || {};
window.AV.internal = window.AV.internal || {};

window.AV.LoginProvider.logout = (href) => logoutCall()
    .catch((err) => {
        logger.warn('Logout call is failed', err);
    })
    .then(() => {
        const hash = getHashFromURL();

        window.localStorage.setItem(SIGN_OUT_KEY, new Date().toString());
        window.sessionStorage.removeItem(currentLayoutDataKey);
        window.sessionStorage.removeItem(clipboardKey);
        window.sessionStorage.removeItem(floatKey);
        window.location.href = href ? `${href}${hash}` : `/${hash}`;
    });

window.AV.LoginProvider.endSession = () => logoutCall()
    .catch((err) => {
        logger.warn('Logout call is failed', err);
    }).finally(() => {
        window.localStorage.setItem(SIGN_OUT_KEY, new Date().toString());
        window.localStorage.removeItem(currentLayoutDataKey);
    });

window.AV.internal.getShowCredentialsAreaToggle = () => localStorage.getItem('avid-unsafe-show-login-area-with-okta') === 'true';

window.AV.internal.showCredentialsAreaWithOkta = () => {
    localStorage.setItem('avid-unsafe-show-login-area-with-okta', 'true');
    logger.info('UNSAFE! For debug purposes only.');
};

window.AV.internal.hideCredentialsAreaWithOkta = () => {
    localStorage.setItem('avid-unsafe-show-login-area-with-okta', 'false');
};
