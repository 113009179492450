/*
 * Copyright 2016-2017, 2019, 2023 by Avid Technology, Inc.
 */

// This is where window.AV is defined once and for all. Don't assign to it anywhere else.
Object.defineProperty(window, 'AV', {
    enumerable: true,
    configurable: false,
    writable: false,
    value: {},
});

export const { AV } = window;
