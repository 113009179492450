/*
 * Copyright 2022-2023 by Avid Technology, Inc.
 */

const portugalLang = 'pt-PT';
const oktaPortugalLang = 'pt-BR';

// https://github.com/okta/okta-signin-widget#supported-languages
const supportedOktaLanguages = [
    'cs', 'da', 'de', 'el', 'en', 'es', 'fi', 'fr', 'hu', 'id', 'it', 'ja', 'ko', 'ms',
    'nb', 'nl-NL', 'pl', 'pt-BR', 'ro', 'ru', 'sv', 'th', 'tr', 'uk', 'zh-CN', 'zh-TW',
];

export default (lang) => {
    if (lang === portugalLang) {
        return oktaPortugalLang;
    }

    if (supportedOktaLanguages.includes(lang)) {
        return lang;
    }

    console.warn(`Okta widget does not support ${lang} language. Fallback to en`);

    return 'en';
};
