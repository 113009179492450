/*
 * Copyright 2020, 2022 by Avid Technology, Inc.
 */

const EMBEDDED_IN = 'embedded_';

const isEmbeddedIn = (mode) => mode.includes(EMBEDDED_IN);

export const getModes = (properties, mode) => {
    const modes = {
        default: { parentId: null },
        main: { parentId: 'default' },
        admin: { parentId: 'default' },
        embedded: { parentId: 'main' },
    };
    const embeddedInMode = { parentId: 'embedded' };
    const unknownMode = { parentId: 'default' };

    if (isEmbeddedIn(mode)) {
        modes[mode] = embeddedInMode;

        return modes;
    }

    if (!(mode in properties)) {
        modes[mode] = unknownMode;
    }

    return modes;
};

export const getFallbackProperty = (properties, modes, mode, key) => {
    const modeSetting = properties[mode];

    if (modeSetting && (key in modeSetting)) {
        return modeSetting[key];
    }

    const parentMode = modes[mode].parentId;

    if (!parentMode) {
        return undefined;
    }

    return getFallbackProperty(properties, modes, parentMode, key);
};
