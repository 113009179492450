/*
 * Copyright 2023 by Avid Technology, Inc.
 */

import { exchangeIdTokenForAccessToken } from '../form/login-call';
import updateUserSettings from '../../settings/updateUserSettings';
import onLoginResponse from '../main/onLoginResponse';
import { createOktaSignIn } from './sso-area';
import localizationStorage from '../localization/storage';
import { WIDGET_ID } from '../../constants';
import logger from '../../../../logger/logger';

/**
 * next styles are needed to fit the width
 * of the widget to the width of the form
 * */
const applyStylesToLoginForm = () => {
    const styles = {
        display: 'flex',
        'flex-flow': 'column',
        'align-items': 'center',
        margin: '19px auto 8px',
    };
    const loginForm = document.getElementById('mcux-login-form');

    Object.keys(styles).forEach((prop) => {
        loginForm.style[prop] = styles[prop];
    });
};

const createOpenIdButton = (getters, onOpenIdLogin) => {
    getters.openIdButton().addEventListener('click', onOpenIdLogin);
    getters.openIdButton().focus();
};

const createOktaButton = (getters, onOktaLogin, onSubmit) => {
    getters.oktaButton().addEventListener('click', onOktaLogin);
    getters.oktaButton().focus();

    if (window.AV.internal.getShowCredentialsAreaToggle()) {
        getters.form().addEventListener('submit', onSubmit);
        getters.nameInput().focus();
    }
};

const showError = (signIn, showRequestError, err) => {
    logger.error('[LoginForm][render] failed to create Okta widget', err);

    signIn.authClient.tokenManager.clear();
    showRequestError();
};

const handleLoginResponse = (isValid, signIn, showRequestMessage, message) => {
    if (!isValid) {
        signIn.authClient.tokenManager.clear();
        signIn.remove();
        showRequestMessage(message);

        return;
    }

    // remove #widget from the hash
    const hash = window.location.hash.replace(WIDGET_ID, '');
    // update url
    window.location.href = `${window.location.origin}${hash}`;
    // reload the page for the limited mode
    if (hash) {
        window.location.reload();
    }
};

const handleIdToken = (ok, status, isAdmin) => {
    if (!ok) {
        throw new Error('Failed to exchange tokenId for accessToken');
    }

    // change language
    const locale = localizationStorage.getLocale();
    updateUserSettings({ locale });

    // verify if user has permissions
    return onLoginResponse({
        response: { status },
        isAdministrator: isAdmin,
    });
};

const handleOktaSignIn = (
    signIn,
    issuer,
    nonce,
    oktaLink,
    isAdmin,
    showRequestMessage,
    showRequestError,
) => {
    signIn.showSignInToGetTokens({
        scopes: ['openid', 'profile'],
    }).then(({ idToken: { idToken } }) => exchangeIdTokenForAccessToken(
        idToken,
        issuer,
        nonce,
        oktaLink,
    )).then(({ ok, status }) => {
        handleIdToken(ok, status, isAdmin);
    }).then(({ isValid, message }) => {
        handleLoginResponse(isValid, signIn, showRequestMessage, message);
    })
        .catch((err) => showError(signIn, showRequestError, err));
};

const createOktaArea = ({
    isAdmin,
    getters,
    isOpenIdDefaultProvider,
    isSsoOktaDefaultProvider,
    isOpenIdEnabled,
    openIdIssuer,
    isSsoOktaEnabled,
    oktaIssuer,
    showRequestMessage,
    showRequestError,
    onOpenIdLogin,
    onOktaLogin,
    onSubmit,
}) => {
    const isWidget = window.location.href.includes(WIDGET_ID);
    const nonce = new Date().getMilliseconds().toString();

    if (isOpenIdEnabled && isOpenIdDefaultProvider) {
        if (isWidget) {
            if (!openIdIssuer) {
                logger.error('[LoginForm][render] openIdIssuer is not provided');

                return;
            }

            applyStylesToLoginForm();

            createOktaSignIn({
                ...openIdIssuer,
                nonce,
                oktaPath: isOpenIdEnabled,
            }).then((signInData) => {
                handleOktaSignIn(
                    signInData,
                    openIdIssuer.href,
                    nonce,
                    isOpenIdEnabled,
                    isAdmin,
                    showRequestMessage,
                    showRequestError,
                );
            });
        } else {
            createOpenIdButton(getters, onOpenIdLogin);
        }
    }

    if (isSsoOktaEnabled && isSsoOktaDefaultProvider) {
        if (isWidget) {
            if (!oktaIssuer) {
                logger.error('[LoginForm][render] oktaIssuer is not provided');

                return;
            }

            applyStylesToLoginForm();

            createOktaSignIn({
                ...oktaIssuer,
                nonce,
                oktaPath: isSsoOktaEnabled,
            }).then((signInData) => {
                handleOktaSignIn(
                    signInData,
                    oktaIssuer.href,
                    nonce,
                    isSsoOktaEnabled,
                    isAdmin,
                    showRequestMessage,
                    showRequestError,
                );
            });
        } else {
            createOktaButton(getters, onOktaLogin, onSubmit);
        }
    }
};

export default createOktaArea;
