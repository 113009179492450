/*
 * Copyright 2018, 2022-2023 by Avid Technology, Inc.
 */

import logger from '../../../../logger/logger';

export default () => fetch('/auth/tokens/current', {
    method: 'delete',
    credentials: 'same-origin',
    headers: {
        Accept: 'application/json',
    },
}).then((response) => {
    if (response.status >= 200 && response.status < 300) {
        logger.info('Logout is successful');
        return;
    }
    logger.warn('Logout is failed, force manual logout', response);
    throw new Error('Request error');
});
