/*
 * Copyright 2018, 2020-2023 by Avid Technology, Inc.
 */

const getLoginErrors = () => ({
    WRONG_CREDENTIALS: {
        status: 400,
        error: 'invalid_grant',
        message: AV.LoginLocalization['wrong-credentials-error'],
    },
    LICENSE_QUOTA_EXCEEDED: {
        status: 402,
        code: 'avid.iam/QUOTA_EXCEEDED',
        message: AV.LoginLocalization['licence-quota-exceeded-error'],
    },
    USER_NOT_IN_GROUP: {
        status: 404,
        code: 'avid.iam/NOT_FOUND',
        message: AV.LoginLocalization['user-not-in-group-error'],
    },
    LICENSE_SEATS_ENTITLEMENT_EXPIRED: {
        status: 410,
        code: 'avid.iam/SEATS_ENTITLEMENT_EXPIRED',
        message: AV.LoginLocalization['licence-seat-entitlement-expired-error'],
    },
    TIMEOUT_USER_AUTHENTICATION: {
        status: 503,
        code: 'avid.iam/SERVICE_UNAVAILABLE',
        params: {
            reason: 'idp_timeout',
        },
        message: AV.LoginLocalization['timeout-user-authentication-error'],
    },
    ACCOUNT_LOCKED_OR_DISABLED: {
        status: 403,
        code: 'avid.iam/FORBIDDEN',
        params: {
            reason: 'account_locked_or_disabled_in_idp',
        },
        message: AV.LoginLocalization['account-locked-or-disabled-error'],
    },
    KERBEROS_UNAUTHORIZED: {
        status: 401,
        code: 'avid.upstream/UNAUTHENTICATED',
        message: AV.LoginLocalization['generic-login-error'],
    },
    KERBEROS_NOT_ALLOWED: {
        status: 405,
        code: 'avid.upstream/METHOD_NOT_ALLOWED',
        message: AV.LoginLocalization['generic-login-error'],
    },
    KERBEROS_NOT_FOUND: {
        status: 404,
        code: 'avid.upstream/METHOD_NOT_FOUND',
        message: AV.LoginLocalization['generic-login-error'],
    },
});

const getValidateMessages = () => ({
    WRONG_CREDENTIALS: AV.LoginLocalization['wrong-credentials-error'],
});

export {
    getLoginErrors,
    getValidateMessages,
};
