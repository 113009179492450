/*
 * Copyright 2018-2024 by Avid Technology, Inc.
 */

import { AV } from './av';
import '../../login/main/login-provider';
import { handleAccessDeniedError, handleFatalError } from '../../util/fatal-error';
import validateLocation from './validateLocation';
import initLoginPromise from '../../login/main/init-login-promise';
import initEulaPromise from '../../eula/main/initEulaPromise';
import { MINIMAL_CHROME_VERSION, showChromeVersionError } from './chromeVersionError';
import browserInfo from './browserInfo';
import setPageTitle from './setPageTitle';
import getInitConfiguration from '../../util/getInitConfiguration';
import initSettings from '../../settings/initSettings';
import {
    initAVBuildVersion,
    initAVUser,
    initAVLayoutManager,
    initSystemsStore,
    saveIamTokenData,
    setCustomerProfile,
    initAVFloatManager,
    initMSChatProvider,
} from './utils';
import initMode from '../../mode/init';
import getApplicationVersion from '../../util/application-version';
import urlHashStorage from '../../util/UrlHashStorage';
import localizationStorage from '../../login/localization/storage';
import logger from '../../../../logger/logger';

const ACCESS_DENIED_CODE = 403;
const ACCESS_DENIED_MESSAGE = 'accessDenied';
const DEFAULT_SEARCH_QUERY_MODE = 'compact';

AV.internal = AV.internal || {};
AV.LoginLocalization = AV.LoginLocalization || {};

/* eslint-disable consistent-return */
export default (initConfiguration) => {
    const { isChrome, chromeVersion } = browserInfo();

    if (isChrome && (chromeVersion < MINIMAL_CHROME_VERSION)) {
        showChromeVersionError();

        return;
    }

    const initialize = () => {
        const {
            isLoggedIn,
            customerProfile,
            identityProviders,
            errorCode,
        } = initConfiguration;

        setCustomerProfile(customerProfile);
        setPageTitle();
        initMode();

        if (errorCode === ACCESS_DENIED_CODE) {
            return Promise.reject(new Error(ACCESS_DENIED_MESSAGE));
        }

        if (!isLoggedIn) {
            return initLoginPromise(identityProviders);
        }

        const hash = urlHashStorage.getItem();

        if (hash && hash !== window.location.hash) {
            urlHashStorage.removeItem();
            window.location.assign(hash);
        }

        return Promise.resolve(initConfiguration);
    };

    const initVersionAndEula = (initInfo) => {
        const getEulaInfo = initInfo ? () => Promise.resolve(initInfo) : getInitConfiguration;

        return Promise.all([getApplicationVersion(), getEulaInfo()])
            .then(async ([{ version, build, release_year: releaseYear }, extendedInitInfo]) => {
                const { userTokenData } = extendedInitInfo;

                saveIamTokenData(userTokenData);
                initAVBuildVersion({ version, build, releaseYear });

                const { eulaSettings } = extendedInitInfo;
                const value = eulaSettings && eulaSettings.value;
                const eulaVersion = value && value.version;

                if (version !== eulaVersion) {
                    AV.LoginLocalization = await localizationStorage.getLocalization();

                    return initEulaPromise(extendedInitInfo);
                }

                return Promise.resolve(extendedInitInfo);
            });
    };

    const initUser = (initInfo) => {
        const {
            username,
            email,
            isAdministrator,
            isAuthorized,
            generalSettings,
            labsSettings,
            userSettings,
            modeFeatureToggles,
        } = initInfo;

        AV.SystemProperties.init(modeFeatureToggles);

        userSettings.searchQueryMode = userSettings.searchQueryMode || DEFAULT_SEARCH_QUERY_MODE;

        return initSettings(
            generalSettings,
            userSettings || generalSettings,
            labsSettings,
        ).then(() => validateLocation(isAdministrator)
            .then((isAdminLocationValid) => {
                if (!isAdminLocationValid) {
                    return Promise.resolve();
                }

                initAVUser({
                    username,
                    email,
                    isAdministrator,
                    isAuthorized,
                });

                return import(/* webpackChunkName: "module-loader" */ '../../module-loader/main/module-loader');
            }));
    };

    return initialize()
        .then(initVersionAndEula)
        .then((initInfo) => Promise.all([
            initUser(initInfo),
            initSystemsStore(),
            initAVLayoutManager(initInfo),
            initAVFloatManager(),
            initMSChatProvider(initInfo),
        ]))
        .catch((err) => {
            const msg = err && err.message;

            if (msg === ACCESS_DENIED_MESSAGE) {
                logger.error('Access denied request iam token');
                AV.LoginProvider.endSession();
                handleAccessDeniedError();
                setTimeout(() => {
                    window.location.href = '/';
                }, 5000);
            } else {
                logger.error('Layout initialization failed', err);
                handleFatalError(new Error('Module loading failed because of a backend issue.'));
                return Promise.reject(err);
            }
        });
};
/* eslint-enable consistent-return */
