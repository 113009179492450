/*
 * Copyright 2017, 2019-2020, 2022 by Avid Technology, Inc.
 */

import {
    get,
    post,
    patch,
    remove,
} from './http-requests';
import {
    getPathLink,
    getCollectionLink,
    getCollectionWithIdLink,
    getCreateFolderLink,
    getDeleteItemLink,
    getSimpleLink,
    getUpdateLink,
    getAddItemsLink,
    getMoveItemsLink,
    getPath,
    getLinkByBaseData,
    getCreateBinLink,
    getCreateFolderRequestConfig,
    getLinksByType,
    getLocale,
} from './utils';

/**
 * LocationItem class.
 * Class LocationItem provides base API for working with location items.
 * getByBaseData - returns location item by base data.
 * getLinks - returns all available item-by-id links.
 * getPath - returns locations item`s path.
 * getChildren - returns folder`s children.
 * copyItems - copies items.
 * moveItems- moves items.
 * renameItem - rename item.
 * isRenamable - returns true if update link exists.
 * createBin - creates project bin.
 * canCreateBin - returns true if create bin link exists.
 * createFolder - creates folder.
 * canCreateFolder - returns true if create folder link exists.
 * canDeleteItem - returns true if delete item link exists.
 * getActionItemLink - get link for certain action.
 * isFolder - check if item is folder.
 */
export default class LocationItem {
    constructor(config) {
        this.locationItem = config.locationItem;
    }

    /**
     * Returns location item by base data.
     * @param {Object} baseData Location item data.
     * @param {String} baseData.systemID Location item system id.
     * @param {String} baseData.systemType Location item system type.
     * @param {String} baseData.id Location item id.
     * @param {Object} queryParams Object that contains query params (key: value)
     * @returns {Promise} Location item.
     */
    static getByBaseData(baseData, queryParams = {}) {
        return this.getLinks().then((links) => {
            let link = getLinkByBaseData(links, baseData);

            if (!link || !link.href) {
                return Promise.reject({
                    errorMessage: 'Link loc:item-by-id do not exist',
                });
            }

            link = link.href.replace('{id}', encodeURIComponent(baseData.id));

            const lang = getLocale();

            return get(link, { ...queryParams, lang });
        });
    }

    /**
     * Returns item-by-id links with information about system.
     * @returns {Promise} Array with item-by-id links.
     */
    static getLinks() {
        return getLinksByType('loc:item-by-id');
    }

    /**
     * Returns locations item`s path.
     * @returns {Object} Location item`s path.
     */
    getPath() {
        const link = getPathLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:path-to-root do not exist',
            });
        }

        return get(link, { embed: 'path' }).then((result) => getPath(result));
    }

    /**
     * @param {String} [config.linkName] link name
     * @param {AbortSignal} [config.signal] abort signal
     * @returns {Promise} data.
     */
    getDataByLink({ linkName, signal }) {
        const link = getSimpleLink(this.locationItem, linkName);

        if (!link) {
            return Promise.reject({
                errorMessage: `Link ${linkName} do not exist`,
            });
        }

        return get(link, '', signal);
    }

    /**
     * Returns folder`s children.
     * @param {Object} config Config.
     * @returns {Object} Folder children.
     */
    getChildren(config) {
        const lang = getLocale();
        const queryParams = { lang };

        if (config) {
            Object.assign(queryParams, config);
        }

        const link = getCollectionLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:collection do not exist',
            });
        }

        return get(link, queryParams);
    }

    /**
     * Returns folder`s children. Children will always include child with provided id.
     * @param id child id.
     * @param {Object} queryParams Config.
     * @returns {Object} Folder children.
     */
    getChildrenWithId(id, queryParams = {}) {
        if (!id) {
            return Promise.reject({
                errorMessage: 'No provided id for loc:collection-at-itemid',
            });
        }

        let link = getCollectionWithIdLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:collection-at-itemid do not exist',
            });
        }

        link = link.replace('{itemid}', encodeURIComponent(id));

        const lang = getLocale();

        return get(link, { ...queryParams, lang });
    }

    /**
     * Returns folder`s children folders by range.
     * @param {Object} [config] query parameters (limit, offset, sort)
     * @returns {Object} Children folders.
     */
    getChildrenFolders(config = {}) {
        const queryParams = {
            filter: 'item-type-folder',
            ...config,
        };

        return this.getChildren(queryParams);
    }

    /**
     * Sends request for copying location items.
     * @param {Array} items Location items list.
     * @return {Promise} Array of copied items.
     */
    copyItems(items) {
        const link = getAddItemsLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Lin loc:add-items do not exist',
            });
        }

        return post(link, items);
    }

    /**
     * Sends request for moving location items.
     * @param {Array} items Location items list.
     * @return {Promise} Array of moved items.
     */
    moveItems(items) {
        const link = getMoveItemsLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:move-items do not exist',
            });
        }

        return post(link, items);
    }

    /**
     * Sends request for renaming folder item or folder.
     * @param {String} name New name of item.
     * @param {Object} [params]
     * @return {Promise} Updated item.
     */
    renameItem(name, params) {
        const link = getUpdateLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:update-item do not exist',
            });
        }

        const body = {
            common: {
                name,
            },
        };

        return patch(link, body, params);
    }

    /**
     * Check if update link exists.
     * @return {Boolean}
     */
    isRenamable() {
        return Boolean(getUpdateLink(this.locationItem));
    }

    /**
     * Sends request for creating folder.
     * @param {Object} config config.
     * @param {String} config.name New folder name.
     * @param {Boolean} config.autoincrement True if auto increment folder name
     * if exist folder with the same name.
     * @return {Promise}
     */
    createFolder(config = {}) {
        const link = getCreateFolderLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:create-folder does not exist',
            });
        }

        const { body } = getCreateFolderRequestConfig(config);

        return post(link, body);
    }

    /**
     * Check if create folder link exist.
     * @return {Boolean}
     */
    canCreateFolder() {
        return Boolean(getCreateFolderLink(this.locationItem));
    }

    /**
     * Check if delete item link exist on parent level.
     * @return {Boolean}
     */
    canDeleteItem() {
        return this.getPath().then((path) => {
            const parentItem = path[path.length - 1];

            return Boolean(parentItem && getDeleteItemLink(parentItem));
        });
    }

    /**
     * Sends request for creating new Media Composer bin.
     * @param {Object} config config.
     * @param {String} config.name New folder name.
     * @return {Promise}
     */
    createBin(config = {}) {
        const link = getCreateBinLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link editorial-management:create-bin does not exist',
            });
        }

        const body = {
            common: {
                name: config.name || 'New.Bin',
            },
        };

        return post(link, body);
    }

    /**
     * Sends request for deleting item.
     * @param {id} item id.
     * @return {Promise}
     */
    deleteItem({ id }) {
        if (!id) {
            return Promise.reject({
                errorMessage: 'id is not provided',
            });
        }

        let link = getDeleteItemLink(this.locationItem);

        if (!link) {
            return Promise.reject({
                errorMessage: 'Link loc:delete-item-by-id does not exist',
            });
        }

        link = link.replace('{id}', encodeURIComponent(id));

        return remove(link);
    }

    /**
     * Returns true if create bin link exists.
     * @return {boolean} true if create bin exists.
     */
    canCreateBin() {
        return Boolean(getCreateBinLink(this.locationItem));
    }

    /**
     * Returns action link by name.
     * @param {String} name Action name.
     * @return {String} Action link.
     */
    getActionItemLink(name) {
        return getSimpleLink(this.locationItem, name);
    }

    /**
     * Returns true if location item is folder.
     * @returns {Boolean} is folder.
     */
    isFolder() {
        return Boolean(getCollectionLink(this.locationItem));
    }
}
