/*
 * Copyright 2020-2021, 2022-2024 by Avid Technology, Inc.
 */

export const SIGN_OUT_KEY = 'core-sign-out';
export const USER_LOCALE_KEY = 'avid-user-locale';
export const brandingProfiles = {
    default: 'default',
    mcem: 'mcem',
};

export const WIDGET_ID = '#widget';

export const OKTA_GRANT_TYPE = 'http://avid.com/oauth/grant_type/ite';
export const clipboardKey = 'AV.ClipboardManager';

export const applicationIds = {
    'com.avid.central.mobile.MediaCentral-Assign': 'collaborate',
    'avid-platform-automation': 'avid-platform-automation',
};
