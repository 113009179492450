/*
 * Copyright 2019-2020, 2023 by Avid Technology, Inc.
 */

import { Registry } from '@avid-technology/ui-ctms-provider';

export default () => Registry.getServiceRoots({ forceRefresh: true })
    .then((serviceRoots) => ({
        systems: Registry.extractSystems(serviceRoots),
        serviceRoots,
    }));
