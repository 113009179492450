/*
 * Copyright 2023 by Avid Technology, Inc.
 */

class StorageManager {
    #storage;

    #key;

    constructor(key, storage) {
        this.#key = key;
        this.#storage = storage || window.sessionStorage;
    }

    removeItem = () => this.#storage.removeItem(this.#key);

    getItem = () => JSON.parse(this.#storage.getItem(this.#key));

    setItem = (data) => this.#storage.setItem(this.#key, JSON.stringify(data));
}

export default StorageManager;
