/*
 * Copyright 2018, 2021-2023 by Avid Technology, Inc.
 */
/**
 * @author anna.kryzhko
 */

import { getFatalErrorDom, getAccessDeniedErrorDom } from './getFatalErrorDom';
import { formatString } from '../login/localization/LanguageStorage';
import logger from '../../../logger/logger';

const loadFatalErrorStyles = () => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = '/core/css/fatal-error.css';
    document.head.appendChild(link);
};

const handleFatalError = (err) => {
    logger.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text
        ? formatString(AV.LoginLocalization['fatal-error-description'], text)
        : AV.LoginLocalization['fatal-error-generic'];
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getFatalErrorDom(text));
};
const handleAccessDeniedError = (err) => {
    logger.error(err);
    let text = (err && typeof err === 'object') ? err.message : err;
    text = text
        ? formatString(AV.LoginLocalization['fatal-error-description'], text)
        : AV.LoginLocalization['fatal-error-access-denied'];
    document.body.innerHTML = '';
    loadFatalErrorStyles();
    document.body.appendChild(getAccessDeniedErrorDom(text));
};

export {
    handleFatalError,
    handleAccessDeniedError,
};
