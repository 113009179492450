/*
 * Copyright 2023 by Avid Technology, Inc.
 */

export default {
    ESC: 'Escape',
    ENTER: 'Enter',
    ARROW_DOWN: 'ArrowDown',
    ARROW_UP: 'ArrowUp',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
    TAB: 'Tab',
    SPACE: 'Space',
    DELETE: 'Delete',
    BACKSPACE: 'Backspace',
};
