/*
 * Copyright 2019, 2022-2023 by Avid Technology, Inc.
 */

export const adminUrl = '/admin';

export default () => {
    const { embeddedIn, mode } = AV.internal;

    if (mode === 'embedded' && embeddedIn) return;

    if (window.location.pathname.startsWith(adminUrl)) {
        window.location.replace('/');
    } else {
        window.location.replace(adminUrl);
    }
};
