/*
 * Copyright 2018, 2020-2023 by Avid Technology, Inc.
 */

import { getLoginErrors } from '../messages';
import logger from '../../../../logger/logger';

const isErrorType = (errorType, { code, error }) => (errorType.error
    ? errorType.error === error
    : errorType.code === code);

const getFailureMessage = (status, data) => {
    const {
        code, error, params, message,
    } = data;
    let errors = Object.values(getLoginErrors()).filter((it) => it.status === status);

    if (params && params.reason) {
        errors = errors.filter((it) => it.params && it.params.reason === params.reason);
    }

    const errorToShow = errors.find((it) => isErrorType(it, { code, error }));

    logger.warn('Login failed', status, data);

    if (!errorToShow && message) {
        return message;
    }

    return (errorToShow && errorToShow.message) || AV.LoginLocalization['generic-login-error'];
};

export default getFailureMessage;
