/*
 * Copyright 2018-2021, 2023 by Avid Technology, Inc.
 */

import eulaTpl from '../view/eula-tpl';

function el(id) {
    return document.getElementById(id);
}

let template;

const eulaFormView = {
    showLoading() {
        el('mcux-eula-loading-outer').style.display = 'block';
        el('mcux-eula-form').style.display = 'none';
    },

    show() {
        el('mcux-eula-loading-outer').style.display = 'none';
        el('mcux-eula-form').style.display = 'block';
    },

    addAgreementText(text) {
        el('license-agreement-text').innerText = text || '';
        el('license-agreement-text').setAttribute('data-ready', true);
    },

    renderRequestError() {
        el('mcux-eula-loading-inner').innerText = AV.LoginLocalization['page-loading-error'];
        el('mcux-eula-loading-outer').style.display = 'flex';
        el('mcux-eula-form').style.display = 'none';
    },

    onSubmit(callback) {
        el('mcux-eula-form').onsubmit = callback;
    },

    render() {
        template = eulaTpl();
        document.body.innerHTML = '';
        document.body.appendChild(template);
    },

    destroy() {
        template.remove();
        template = null;
    },

    renderSpinner() {
        const spinner = document.createElement('div');
        spinner.setAttribute('id', 'avid-nux-page-load-indicator');
        spinner.setAttribute('class', 'nux-loading-spinner-large');
        document.body.appendChild(spinner);
    },
};

export default eulaFormView;
