/*
 * Copyright 2024 by Avid Technology, Inc.
 */

const defineLabsSettingsProperties = (key, value) => {
    Object.defineProperty(
        AV.internal.labsSettings,
        key,
        {
            value,
            writable: false,
            enumerable: true,
        },
    );
};

export default (settings = {}) => {
    AV.internal.labsSettings = {};

    Object.keys(settings).forEach((key) => {
        if (['allValuesAreValid', 'isPocLabTab'].includes(key)) {
            return;
        }

        const value = settings[key];

        defineLabsSettingsProperties(key, value);
    });
};
