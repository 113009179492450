/*
 * Copyright 2022-2023 by Avid Technology, Inc.
 */

export class Logger {
    constructor(app) {
        this.app = app;
    }

    info(...message) {
        // eslint-disable-next-line no-console
        console.log(this.app, ...message);
    }

    error(...message) {
        console.error(this.app, ...message);
    }

    warn(...message) {
        console.warn(this.app, ...message);
    }

    debug(...message) {
        // eslint-disable-next-line no-console
        console.debug(this.app, ...message);
    }
}

export default new Logger('[CORE]');
