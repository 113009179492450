/*
 * Copyright 2013-2018, 2021-2024 by Avid Technology, Inc.
 */

import { ADMIN_LOCALE } from '../../../configs/admin-locale';

export default function ({
    name,
    email,
    locale,
    isAdministrator,
    isAuthorized,
    searchQueryMode,
    timeZone,
}) {
    if (!name) {
        throw new Error('name property must be present in config');
    }

    if (!locale) {
        throw new Error('locale property must be present in config');
    }

    const readOnly = (param) => ({
        value: param,
        writable: false,
        enumerable: true,
    });

    const readWrite = (param) => ({
        value: param,
        writable: true,
        enumerable: true,
    });

    return Object.create({}, {
        name: readOnly(name),
        ...email && ({ email: readOnly(email) }),
        searchQueryMode: readWrite(searchQueryMode),
        isAdministrator: {
            get() {
                return isAdministrator;
            },
        },
        isAuthorized: {
            get() {
                return isAuthorized;
            },
        },
        locale: isAdministrator ? readOnly(ADMIN_LOCALE) : readOnly(locale),
        timeZone: readWrite(timeZone),
    });
}
