/*
 * Copyright 2023 by Avid Technology, Inc.
 */

class ModalsManager {
    constructor() {
        // Map to store modal IDs and close functions
        this.modalRegistry = new Map();
        // Set to store open modal dialogs
        this.modals = new Set();
    }

    // open a modal dialog by ID and close all other modal dialogs
    openModal(id, closeFunction) {
        if (typeof closeFunction !== 'function') {
            throw new Error('[ModalsManager] openModal: second parameter must be a function with close modal dialog method.');
        }

        this.modalRegistry.set(id, closeFunction);

        // modal is already open, do nothing
        if (this.modals.has(id)) {
            return;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const currentModal of this.modals) {
            // modal is already open
            if (currentModal === id) {
                break;
            }

            const closeFunc = this.modalRegistry.get(currentModal);

            closeFunc();

            this.modals.delete(currentModal);
        }

        this.modals.add(id);
    }
}

export default ModalsManager;
