/*
 * Copyright 2022-2023 by Avid Technology, Inc.
 */

import {
    currentLayoutDataKey,
    sortOrderKey,
} from './sessionStorageKeys';
import Observer from '../../util/Observer';

class LayoutManager extends Observer {
    #layoutStorage;

    #sortLayoutOrderStorage;

    constructor({ body }, { layoutStorage, sortLayoutOrderStorage }) {
        super();
        this.#layoutStorage = layoutStorage;
        this.#sortLayoutOrderStorage = sortLayoutOrderStorage;
        const currentLayoutData = this.#layoutStorage.getItem(currentLayoutDataKey);

        // after login
        if (!currentLayoutData) {
            this.currentLayout = {
                ...body,
            };
        }

        // after refresh
        if (currentLayoutData) {
            this.currentLayout = {
                ...currentLayoutData,
            };
        }
    }

    setItem(componentId, { ...rest }) {
        this.currentLayout = {
            ...this.currentLayout,
            [componentId]: {
                ...this.currentLayout[componentId],
                ...rest,
            },
        };

        this.#layoutStorage.setItem(this.currentLayout);
    }

    getItem(componentId) {
        return JSON.parse(JSON.stringify(this.currentLayout[componentId] || {}));
    }

    removeItemByAppType(appType, appId) {
        Object.keys(this.currentLayout)
            .forEach((item) => {
                if (appId && item.includes(appId) && item.includes(appType)) {
                    delete this.currentLayout[item];
                }

                if (!appId && item.includes(appType)) {
                    delete this.currentLayout[item];
                }
            });

        this.#layoutStorage.setItem(this.currentLayout);
    }

    setCurrentLayout(layoutData) {
        this.currentLayout = layoutData;
        this.#layoutStorage.setItem(layoutData);
        this.update(layoutData);
    }

    getCurrentLayout() {
        return JSON.parse(JSON.stringify(this.currentLayout));
    }

    setSortOrder(sortOrder) {
        this.#sortLayoutOrderStorage.setItem(sortOrder);
    }

    getSortOrder() {
        return this.#sortLayoutOrderStorage.getItem(sortOrderKey);
    }
}

export default LayoutManager;
