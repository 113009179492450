/*
 * Copyright 2013-2016, 2018-2020, 2022-2023 by Avid Technology, Inc.
 */

const dataBrowser = [
    {
        string: navigator.userAgent,
        subString: 'Edg',
        versionSearch: 'Edg',
        identity: 'Edge',
    },
    {
        string: navigator.userAgent,
        subString: 'OPR',
        identity: 'Opera',
    },
    {
        string: navigator.userAgent,
        subString: 'Chrome',
        identity: 'Chrome',
    },
    {
        string: navigator.userAgent,
        subString: 'QtWeb Internet Browser',
        identity: 'QtWeb',
    },
    {
        string: navigator.vendor,
        subString: 'Apple',
        identity: 'Safari',
        versionSearch: 'Version',
    },
    {
        prop: window.opera,
        identity: 'Opera',
        versionSearch: 'Version',
    },
    {
        string: navigator.vendor,
        subString: 'iCab',
        identity: 'iCab',
    },
    {
        string: navigator.userAgent,
        subString: 'Firefox',
        identity: 'Firefox',
    },
    {
        string: navigator.userAgent,
        subString: 'MSIE',
        identity: 'Internet Explorer',
        versionSearch: 'MSIE',
    },
    {
        string: navigator.userAgent,
        subString: 'Trident',
        identity: 'Internet Explorer',
    },
    {
        string: navigator.userAgent,
        subString: 'Gecko',
        identity: 'Mozilla',
        versionSearch: 'rv',
    },
    { // for older Netscapes (4-)
        string: navigator.userAgent,
        subString: 'Mozilla',
        identity: 'Netscape',
        versionSearch: 'Mozilla',
    },
];
const dataOS = [
    {
        string: navigator.platform,
        subString: 'Win',
        identity: 'Windows',
    },
    {
        string: navigator.platform,
        subString: 'Mac',
        identity: 'Mac',
    },
    {
        string: navigator.userAgent,
        subString: 'iPhone',
        identity: 'iPhone/iPod',
    },
    {
        string: navigator.platform,
        subString: 'Linux',
        identity: 'Linux',
    },
];

const dataExtensions = [
    {
        string: navigator.userAgent.toLowerCase(),
        subString: 'qt/',
        identity: 'QtClient',
    },
];
// isWindows and isMac have been copied from UICore/src/main/ui/js/util/userAgent.js
// TODO: have only one copy of this code across the MCUX codebase
const ua = navigator.userAgent.toLowerCase();
export const isWindows = /windows|win32/.test(ua);
export const isMac = /macintosh|mac os x/.test(ua);
export const browserName = searchString(dataBrowser) || undefined;
export const OS = searchString(dataOS) || undefined;
const extensions = searchStringArr(dataExtensions);
let versionSearchString;
let preliminaryBrowserVersion;
export const browserVersion = preliminaryBrowserVersion || searchVersion(navigator.userAgent) || searchVersion(navigator.appVersion) || undefined;

// CORE-2738 - As a WebKit developer I want my client to be accepted
// as a supported client on the login screen
// user agent for test
// Mozilla/5.0 (Windows NT 6.1; Win64; x64)
// AppleWebKit/534.34 (KHTML, like Gecko) Qt/4.8.3 Safari/534.34
export function isQtClient() {
    return extensions && extensions.indexOf('QtClient') !== -1;
}

export const isChrome = (/\bchrome\b/i).test(window.navigator.userAgent)
    && !(/edg/i).test(window.navigator.userAgent)
    && !(/opr/i).test(window.navigator.userAgent);

function searchString(data) {
    for (let i = 0; i < data.length; i++) {
        const dataString = data[i].string;
        const dataProp = data[i].prop;

        if (dataString) {
            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        } else if (dataProp) {
            return data[i].identity;
        }
    }
}

function searchStringArr(data) {
    return data.map(_isContain).filter((elem) => elem !== '');
}

function _isContain(elem) {
    const dataString = elem.string;

    if (dataString && dataString.indexOf(elem.subString) !== -1) {
        return elem.identity;
    }

    return '';
}

function searchVersion(dataString) {
    const index = dataString.indexOf(versionSearchString);

    if (index === -1) {
        return;
    }

    return parseFloat(dataString.substring(index + versionSearchString.length + 1));
}
