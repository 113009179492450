/*
 * Copyright 2017, 2020-2022, 2023 by Avid Technology, Inc.
 */

// region Methods: Public
import getHeaders from './getHeaders';

function getCredentials() {
    return 'same-origin';
}

function status(response) {
    if ((response.status >= 200 && response.status < 300) || response.ok) {
        return Promise.resolve(response.json());
    }

    return Promise.reject(response);
}

function addParamsToUrl(url, params) {
    const paramString = params && Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&');
    let resultUrl = url;

    if (paramString) {
        resultUrl += `?${paramString}`;
    }

    return resultUrl;
}

function error(err = {}) {
    return new Promise((resolve, reject) => {
        if (err.json) {
            err.json().then(reject).catch(() => {
                if (err.status === 404) {
                    reject({
                        status: err.status,
                        response: err.response || 'Resource not found',
                    });
                }
            });
        } else {
            reject({
                status: err.status,
                response: err.response,
            });
        }
    });
}

function get(url, params, signal) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);

    return fetch(urlWithParams, {
        method: 'GET',
        credentials: getCredentials(),
        headers: getHeaders(),
        signal,
    })
        .then(status)
        .catch(error);
}

function post(url, body, params, signal) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    const headers = new Headers({
        Accept: 'application/hal+json',
        'Content-Type': 'application/json',
    });

    return fetch(urlWithParams, {
        method: 'POST',
        mode: 'cors',
        credentials: getCredentials(),
        headers,
        body: JSON.stringify(body),
        signal,
    })
        .then(status)
        .catch(error);
}

function patch(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);
    const headers = new Headers({
        Accept: 'application/hal+json',
        'Content-Type': 'application/json',
    });

    return fetch(urlWithParams, {
        method: 'PATCH',
        mode: 'cors',
        credentials: getCredentials(),
        headers,
        body: JSON.stringify(body),
    })
        .then(status)
        .catch(error);
}

function remove(url, body, params) {
    const urlWithParams = addParamsToUrl(url.split('?')[0], params);

    return fetch(urlWithParams, {
        method: 'DELETE',
        mode: 'cors',
        credentials: getCredentials(),
        headers: getHeaders(),
        body: JSON.stringify(body),
    })
        .then((response) => (response.status === 204
            ? Promise.resolve()
            : Promise.reject(response)))
        .catch(error);
}

export {
    get, post, patch, remove,
};
