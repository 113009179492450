/*
 * Copyright 2014-2019, 2021-2023 by Avid Technology, Inc.
 */

import {
    OS, browserName, browserVersion, isQtClient, isChrome, isWindows, isMac,
} from './browserAndOsDetection';
import { formatString } from '../localization/LanguageStorage';

export function getBrowserWarning() {
    if (!isQtClient()) {
        if (!isWindows && !isMac) {
            return AV.LoginLocalization['unsupported-operation-system'];
        }

        if (!isChrome) {
            const browserNameAndVersion = browserVersion ? `${browserName} ${browserVersion}` : browserName;

            return formatString(AV.LoginLocalization['unsupported-operation-system-and-browser'], browserNameAndVersion, OS);
        }
    }
}
