/*
 * Copyright 2024 by Avid Technology, Inc.
 */
import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr';
import { Providers } from '@microsoft/mgt-element';
import getToken from './getToken';
import getExpirationDateTime from './getExpirationDateTime';
import normalizeNotificationUrl from './normilizeNotificationUrl';
import logger from '../../../../../logger/logger';

const subscribeToChatNotifications = async (chatId, onMessageCallback) => {
    const client = Providers.globalProvider?.graph?.client;
    if (client) {
        const options = {
            changeType: 'created',
            notificationUrl: 'websockets:',
            resource: `/chats/${chatId}/messages`,
            includeResourceData: true,
            expirationDateTime: getExpirationDateTime(),
            clientState: 'avid-mccux-secret-state',
        };

        try {
            const { notificationUrl } = await client.api('/subscriptions').post(options);

            const connectionOptions = {
                withCredentials: false,
                accessTokenFactory: getToken,
            };

            const conection = new HubConnectionBuilder()
                .withUrl(normalizeNotificationUrl(notificationUrl), connectionOptions)
                .configureLogging(LogLevel.Error)
                .withAutomaticReconnect()
                .build();

            conection.start();

            conection.on('receivenotificationmessageasync', onMessageCallback);
        } catch (e) {
            logger.error(`[MSChatProvider] Failed to subscribe to chat ${chatId}: `, e);
        }
    }
};

export default subscribeToChatNotifications;
