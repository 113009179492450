/*
 * Copyright 2024 by Avid Technology, Inc.
 */
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import logger from '../../../../logger/logger';
import { MS_CHAT_POLLING_INTERVAL_KEY } from './constants';
import getChatsFilterDate from './helpers/getChatsFilterDate';
import removeActiveSubscriptions from './helpers/removeActiveSubscriptions';
import subscribeToChatNotifications from './helpers/subscribeToChatNotifications';

const SCOPES = [
    'calendars.read', 'user.read',
    'openid', 'profile', 'people.read',
    'user.readbasic.all', 'Chat.ReadBasic',
    'Chat.Read', 'Chat.ReadWrite', 'email',
];

const CHATS_POLLING_INTERVAL = 10000;

class MSChatProvider {
    #providers = null;

    #isSignedIn = false;

    #isApplicationIdProvided = false;

    #privateData = {
        value: [],
    };

    #msEntraApplicationId = '';

    #pollingIntervalId;

    #pollingInterval = CHATS_POLLING_INTERVAL;

    #onChatsUpdateSubscriptions = [];

    constructor(msEntraApplicationId) {
        this.#msEntraApplicationId = msEntraApplicationId;
        this.#isApplicationIdProvided = !!msEntraApplicationId;

        const pollingInterval = localStorage.getItem(MS_CHAT_POLLING_INTERVAL_KEY);

        if (pollingInterval) {
            this.#pollingInterval = Number(pollingInterval);
        }
    }

    set #data(data) {
        this.#privateData = data;
        this.#notifyChatsUpdateListeners();
    }

    get #data() {
        return this.#privateData;
    }

    async init() {
        try {
            if (!this.#isApplicationIdProvided) {
                throw new Error('Application ID is not provided');
            }

            Providers.globalProvider = new Msal2Provider({
                clientId: this.#msEntraApplicationId,
                redirectUri: window.location.origin,
                scopes: SCOPES,
            });

            Providers.onProviderUpdated(async () => {
                const isSignedIn = Providers.globalProvider.state === ProviderState.SignedIn;
                this.#isSignedIn = isSignedIn;

                if (isSignedIn) {
                    await removeActiveSubscriptions();
                    await this.#startPolling();
                } else {
                    logger.warn('[MSChatProvider] User is not signed in to MS Chat');
                }
            });

            this.#providers = Providers;
        } catch (e) {
            logger.error('[MSChatProvider] Error initializing MSChatProvider', e);
        }
    }

    subscribeOnChatsUpdate = (callback) => {
        callback(this.#data);
        this.#onChatsUpdateSubscriptions.push(callback);

        return () => {
            this.#onChatsUpdateSubscriptions = this.onChatsUpdateSubscriptions?.filter(
                (subscription) => subscription !== callback,
            );
        };
    };

    #onMeesageUpdateReceived = async (message) => {
        try {
            const parsedMessage = JSON.parse(message);

            const {
                resourceData: {
                    chatId,
                    body,
                    lastModifiedDateTime,
                },
            } = parsedMessage;

            const chatToChangeIndex = this.#data.value.findIndex((chat) => chat.id === chatId);
            const chatToChange = this.#data.value[chatToChangeIndex];

            if (chatToChange) {
                chatToChange.lastMessagePreview.body = body;
                chatToChange.lastMessagePreview.createdDateTime = lastModifiedDateTime;
                const newData = [
                    chatToChange,
                    ...this.#data.value.slice(0, chatToChangeIndex),
                    ...this.#data.value.slice(chatToChangeIndex + 1),
                ];
                this.#data = {
                    ...this.#data,
                    value: newData,
                };
            }
        } catch (error) {
            logger.error('[MSChatProvider] Failed to parse message', error, message);
        }
    };

    #subscribeToChatsByIds = async (ids) => {
        const chatsToSubscribe = ids.slice(0, 5);
        const chatsToPostponeSubscription = ids.slice(5);

        const subscriptions = chatsToSubscribe
            .filter((chat) => chat)
            .map((chat) => subscribeToChatNotifications(chat, this.#onMeesageUpdateReceived));

        await Promise.all(subscriptions);

        if (chatsToPostponeSubscription.length) {
            await this.#subscribeToChatsByIds(chatsToPostponeSubscription);
        }
    };

    #getChats = async () => {
        try {
            const filterDate = getChatsFilterDate();

            const data = await this.getClient()
                .api('/me/chats')
                .filter(`lastMessagePreview/createdDateTime ge ${filterDate}`)
                .orderby('lastMessagePreview/createdDateTime desc')
                .expand('members')
                .expand('lastMessagePreview')
                .top(20)
                .get();

            await this.#updateSubscriptions(data);

            this.#data = data;
        } catch (e) {
            const message = e?.message || 'Failed to get chat list';
            logger.error(`[MSChatProvider] ${message}`, e);
        }
    };

    #notifyChatsUpdateListeners = () => {
        this.#onChatsUpdateSubscriptions.forEach((subscription) => {
            subscription(this.#data);
        });
    };

    #updateSubscriptions = async (data) => {
        const oldChatsIds = this.#data.value.map(({ id }) => id);
        const newChatsIds = data.value.map(({ id }) => id);
        const notChangedChatsIds = Array
            .from(new Set(oldChatsIds).intersection(new Set(newChatsIds)));

        const idsToRemoveSubscriptions = oldChatsIds
            .filter((id) => !notChangedChatsIds.includes(id));
        const idsToSubscribe = newChatsIds
            .filter((id) => !notChangedChatsIds.includes(id));

        // remove chats subscriptions which are not in the new list
        await removeActiveSubscriptions(idsToRemoveSubscriptions);
        // subscribe to new chats in the list
        await this.#subscribeToChatsByIds(idsToSubscribe);
    };

    async #startPolling() {
        await this.#getChats();

        this.#pollingIntervalId = setInterval(() => {
            this.#getChats();
        }, this.#pollingInterval);
    }

    #endPolling() {
        clearInterval(this.#pollingIntervalId);
    }

    getProvider() {
        return this.#providers?.globalProvider;
    }

    getClient() {
        return this.#providers?.globalProvider?.graph?.client;
    }

    isSignedIn() {
        return this.#isSignedIn && this.#isApplicationIdProvided;
    }

    isApplicationIdProvided() {
        return this.#isApplicationIdProvided;
    }

    getAccount() {
        return this.#providers?.globalProvider?.getAccount();
    }

    async signOut() {
        this.#providers?.globalProvider?.logout();
        this.#onChatsUpdateSubscriptions = [];
        this.#endPolling();
        this.#data = { value: [] };
        await removeActiveSubscriptions();

        logger.info('[MSChatProvider] Signed out');
    }
}

export default MSChatProvider;
