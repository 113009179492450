/*
 * Copyright 2022, 2023 by Avid Technology, Inc.
 */

import { WIDGET_ID } from '../constants';

export default function getHashFromURL() {
    const { hash } = window.location;

    if (!hash) return '';

    const [, result] = hash.replace(WIDGET_ID, '').split('#');
    return result ? `#${result}` : '';
}
