/*
 * Copyright 2023 by Avid Technology, Inc.
 */

class FloatManager {
    #floatStorage;

    constructor(floatStorage) {
        this.#floatStorage = floatStorage;
        this.floatData = this.#floatStorage.getItem() || {};
    }

    setFloatAppPosition = (appType, position) => {
        this.floatData = {
            ...this.floatData,
            [appType]: {
                ...this.floatData[appType],
                position,
            },
        };

        this.#floatStorage.setItem(this.floatData);
    };

    setFloatAppHeight = (appType, height) => {
        this.floatData = {
            ...this.floatData,
            [appType]: {
                ...this.floatData[appType],
                height,
            },
        };

        this.#floatStorage.setItem(this.floatData);
    };

    getFloatAppPosition = (appType) => {
        const floatData = this.#floatStorage.getItem() || {};

        return floatData[appType]?.position || {};
    };

    getFloatAppHeight = (appType) => {
        const floatData = this.#floatStorage.getItem() || {};

        return floatData[appType]?.height || null;
    };
}

export default FloatManager;
