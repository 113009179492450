/*
 * Copyright 2023 by Avid Technology, Inc.
 */

import StorageManager from '../initialization-flow/main/StorageManager';

export const floatKey = 'AV.FloatManager';

export class FloatStorage extends StorageManager {
    constructor(storage) {
        super(floatKey, storage);
    }
}

const floatStorage = new FloatStorage();
export default floatStorage;
