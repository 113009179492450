/*
 * Copyright 2017, 2019-2022 by Avid Technology, Inc.
 */

import hal from 'halfred';
import Registry from './registry';

const HAL_OPTIONS_START_TOKEN = '{?';
const AVID_CONTAINER = 'avid-container';

function resolveLink(link) {
    const optionsStart = link.indexOf(HAL_OPTIONS_START_TOKEN);

    if (optionsStart !== -1) {
        return link.substr(0, optionsStart);
    }

    return link;
}

function getSimpleLink(item, linkName) {
    const resource = hal.parse(item);
    const linkObj = resource.link(linkName);

    if (!linkObj) {
        return null;
    }

    const { href, templated } = linkObj;

    return templated ? resolveLink(href) : href;
}

function getCollectionLink(item) {
    return getSimpleLink(item, 'loc:collection');
}

function getCollectionWithIdLink(item) {
    return getSimpleLink(item, 'loc:collection-at-itemid');
}

function getPathLink(item) {
    return getSimpleLink(item, 'loc:path-to-root');
}

function getReferencingItemsLink(item) {
    return getSimpleLink(item, 'loc:referencing-items');
}

function getCreateBinLink(item) {
    return getSimpleLink(item, 'editorial-management:create-bin');
}

function getCreateFolderLink(item) {
    return getSimpleLink(item, 'loc:create-folder');
}

function getDeleteItemLink(item) {
    return getSimpleLink(item, 'loc:delete-item-by-id');
}

function getLocationLink(item) {
    return getSimpleLink(item, 'loc:locations');
}

function getUpdateLink(item) {
    return getSimpleLink(item, 'loc:update-item');
}

function getAddItemsLink(item) {
    return getSimpleLink(item, 'loc:add-items');
}

function getMoveItemsLink(item) {
    return getSimpleLink(item, 'loc:move-items');
}

function getRootItemLink(item) {
    return getSimpleLink(item, 'loc:root-item');
}

function getDataModel(item) {
    const dataModel = hal.parse(item);

    return dataModel.resources['datamodel:aggregated-model'][0];
}

function getDataModelLink(linkResource) {
    const { href, templated } = linkResource;

    return templated ? resolveLink(href) : href;
}

function getPath(item) {
    const resource = hal.parse(item);

    return resource.embeddedArray('loc:path') || [];
}

function getLinkByBaseData(links, baseData) {
    return links.find((link) => link.systemID === baseData.systemID
        && link.systemType === baseData.systemType);
}

function isSystemAvailableForLink(resources, system, linkName) {
    const resourceInfos = resources[linkName] || [];

    return resourceInfos.some((resourceInfo) => getLinkByBaseData(resourceInfo.systems, system));
}

function filterBrowsableSystems(resources, system) {
    // Fix for JP-7474 (https://avid-ondemand.atlassian.net/browse/JP-7474)
    const { systemType } = system;

    if (systemType === AVID_CONTAINER) {
        return false;
    }

    const resource = hal.parse(resources);

    return isSystemAvailableForLink(resource, system, 'loc:root-item')
        || isSystemAvailableForLink(resource, system, 'loc:locations');
}

function getLinks(links) {
    const results = [];

    if (Array.isArray(links)) {
        links.forEach((link) => {
            const { href } = link;

            link.systems.forEach((system) => {
                const { systemID, systemType } = system;

                results.push({ href, systemType, systemID });
            });
        });
    }

    return results;
}

function getCreateFolderRequestConfig(data) {
    const body = {
        common: {
            name: data.name || 'New.Folder',
        },
    };
    // eslint-disable-next-line no-prototype-builtins
    const autoincrement = data.hasOwnProperty('autoincrement')
        ? data.autoincrement
        : true;

    return {
        body,
        autoincrement,
    };
}

function getLinksByType(type) {
    return Registry.getServiceRoots()
        .then((response) => {
            const links = response.resources[type];

            return getLinks(links);
        });
}

const getLocale = () => window.AV.User.locale;

export {
    getSimpleLink,
    filterBrowsableSystems,
    getCollectionLink,
    getCollectionWithIdLink,
    getPathLink,
    getCreateFolderLink,
    getDeleteItemLink,
    getLocationLink,
    getUpdateLink,
    getAddItemsLink,
    getMoveItemsLink,
    getRootItemLink,
    getReferencingItemsLink,
    getDataModel,
    getDataModelLink,
    getPath,
    getLinks,
    getLinkByBaseData,
    getCreateFolderRequestConfig,
    getCreateBinLink,
    getLinksByType,
    getLocale,
};
