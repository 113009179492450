/*
 * Copyright 2024 by Avid Technology, Inc.
 */

const getChatsFilterDate = () => {
    const currentDate = new Date();

    // 177 days ago
    currentDate.setDate(currentDate.getDate() - 177);

    return currentDate.toISOString();
};

export default getChatsFilterDate;
