/*
 * Copyright 2023 by Avid Technology, Inc.
 */

import StorageManager from '../initialization-flow/main/StorageManager';

class UrlHashStorage extends StorageManager {
    constructor() {
        super('avid-url-hash', window.localStorage);
    }
}

const urlHashStorage = new UrlHashStorage();
export default urlHashStorage;
