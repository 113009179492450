/*
 * Copyright 2021-2023 by Avid Technology, Inc.
 */

import { USER_LOCALE_KEY } from '../../constants';
import { ADMIN_LOCALE } from '../../../configs/admin-locale';
import languages from '../../../../configs/languages.json';
import logger from '../../../../logger/logger';

const defaultLocale = 'en';
const fetchHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
});

const isExistingLocale = (locale) => {
    const locales = languages.map((lang) => lang.value);

    return locales.includes(locale);
};

const sanitize = (locale) => {
    if (!locale) return undefined;

    return isExistingLocale(locale) ? locale : defaultLocale;
};

export function formatString(str, ...param) {
    const formatRe = /{(\d{1,3})}/g;

    return str.replace(formatRe, (m, i) => param[i]);
}

export default class LanguageStorage {
    constructor({ isAdmin }) {
        this.isAdmin = isAdmin;

        if (isAdmin) {
            this.locale = ADMIN_LOCALE;
        } else {
            this.locale = sanitize(localStorage.getItem(USER_LOCALE_KEY));
        }
    }

    getLocale = () => this.locale;

    setLocale = (locale) => {
        if (this.isAdmin) {
            return;
        }

        this.locale = sanitize(locale);
        localStorage.setItem(USER_LOCALE_KEY, locale);
    };

    getLocalization = async () => {
        const locale = this.getLocale() || defaultLocale;
        let localizationData = {};

        try {
            const response = await fetch(`${window.location.origin}/core/l10n/login.${locale}.json`, { headers: fetchHeaders });

            if ((response.status >= 200 && response.status < 300) || response.ok) {
                localizationData = await response.json();
            }

            if (!response.ok) {
                throw new Error(`HTTP error: ${response.status}`);
            }
        } catch (error) {
            logger.error('Error on fetching localization file:', error);

            return {};
        }

        return localizationData;
    };
}
