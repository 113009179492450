/*
 * Copyright 2018-2023, 2024 by Avid Technology, Inc.
 */

import getFailureMessage from './getFailureMessage';
import getInitConfiguration from '../../util/getInitConfiguration';
import getEmbeddedInMode from '../../util/getEmbeddedInMode';
import getHashFromURL from '../../util/getHashFromURL';
import urlHashStorage from '../../util/UrlHashStorage';
import logger from '../../../../logger/logger';
import '../../module-loader/main/system-properties/SystemProperties';

const GROUP_QUOTA_LIMIT_EXCEEDED = 'GROUP_QUOTA_LIMIT_EXCEEDED';

const getJson = (response) => Promise.resolve().then(() => response.json()).catch((err) => {
    logger.warn('Error with parsing login result:', err);
    throw new Error('Unexpected response');
});

export default ({ response, isAdministrator }) => {
    const { status } = response;
    const result = {
        isValid: false,
        message: '',
    };
    const onError = (message) => {
        AV.LoginProvider.endSession();
        result.message = message;
        return result;
    };

    if (status === 200) {
        if (!isAdministrator) {
            return getInitConfiguration()
                .then((initData) => {
                    const {
                        hasPermissions, status: statusCode, code, message,
                    } = initData;

                    result.isValid = hasPermissions;

                    if (statusCode === 401 && code.includes(GROUP_QUOTA_LIMIT_EXCEEDED)) {
                        return onError(message);
                    }

                    if (!hasPermissions && getEmbeddedInMode() !== null) {
                        return onError(window.AV.LoginLocalization['panel-entitlement-check-error']);
                    }

                    if (!hasPermissions) {
                        return onError(window.AV.LoginLocalization['entitlement-check-error']);
                    }

                    return result;
                })
                .catch((err) => {
                    const { status: statusCode } = err;
                    if (statusCode === 403) {
                        return onError(window.AV.LoginLocalization['entitlement-check-error']);
                    }
                    throw err;
                });
        }
        result.isValid = true;
        return result;
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        result.message = msg || '';
        return result;
    });
};

export const onOktaLoginResponse = ({ response }) => {
    const { status } = response;
    const hash = getHashFromURL();

    if (hash) {
        urlHashStorage.setItem(hash);
    }

    if (status === 200) {
        return getJson(response)
            .then((redirectData) => {
                window.location.assign(redirectData.location);

                return Promise.resolve({
                    oktaRedirect: true,
                });
            });
    }
    return getJson(response).then((data) => {
        const msg = getFailureMessage(status, data);
        return {
            isValid: false,
            message: msg || '',
        };
    });
};
