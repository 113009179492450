/*
 * Copyright 2021-2023 by Avid Technology, Inc.
 */

import OktaSignIn from '@okta/okta-signin-widget';
import createStylesElement from './createStylesElement';
import styles from './styles/sso-area-styles.scss';
import localizationStorage from '../localization/storage';
import convertToOktaLang from '../../util/convertToOktaLang';
import logger from '../../../../logger/logger';

export function createKerberosButton({ onLogin }) {
    const kerberosButton = createStylesElement({
        el: 'button',
        id: 'sso-button',
        classNames: [styles['sso-button']],
    });
    kerberosButton.innerText = AV.LoginLocalization['sso-sign-in-button'];
    kerberosButton.addEventListener('click', onLogin);

    return {
        getElement() {
            return kerberosButton;
        },
        focus() {
            if (kerberosButton) {
                kerberosButton.focus();
            }
        },
        destroy() {
            kerberosButton.removeEventListener('click', onLogin);
            kerberosButton.remove();
        },
    };
}

export function createSsoArea() {
    const area = createStylesElement({
        el: 'fieldset',
        classNames: [styles.container],
    });
    const legend = createStylesElement({
        el: 'legend',
        classNames: [styles['sso-legend']],
    });
    legend.innerText = AV.LoginLocalization['sso-sign-in-label'];
    area.appendChild(legend);

    return {
        getElement() {
            return area;
        },
        append(button) {
            area.append(button);
        },
    };
}

export function createOktaSignIn({
    href, clientId, nonce, oktaPath,
}) {
    const https = 'https://';
    const baseUrl = href.includes(https) ? href : `${https}${href}`;

    // added dynamic import to prevent importing if the Okta widget is not on the screen
    return import('./okta-styles/css/okta-sign-in.min.css')
        .then(() => import('./okta-styles/img/img.png'))
        .then((module) => module.default)
        .then((logo) => {
            const signIn = new OktaSignIn({
                baseUrl,
                clientId,
                redirectUri: `${window.location.origin}${oktaPath}`,
                el: '#mcux-login-form',
                logo,
                language: convertToOktaLang(localizationStorage.getLocale()),
                authParams: {
                    pkce: false,
                    issuer: baseUrl,
                    nonce,
                },
            });

            // added logging for errors
            signIn.on('afterError', (context, error) => {
                logger.error('[Okta widget] context', context);
                logger.error('[Okta widget] error', error);
            });

            return signIn;
        });
}
