/*
 * Copyright 2018-2020, 2022-2023 by Avid Technology, Inc.
 */

import getInitConfiguration from '../../util/getInitConfiguration';
import { AV } from './av';
import localizationStorage from '../../login/localization/storage';
import initLayout from './initLayout';
import {
    initModalsManager,
    initKeyNavigationHandler,
    initLogger,
} from './utils';
import logger from '../../../../logger/logger';

const initialize = async () => {
    let initConfiguration;

    try {
        initConfiguration = await getInitConfiguration();
        const { isLoggedIn } = initConfiguration;

        if (!isLoggedIn) {
            AV.LoginLocalization = await localizationStorage.getLocalization();
        }
    } catch (error) {
        logger.error('Error on initialize:', error);
    }

    initLogger();
    initKeyNavigationHandler();
    initModalsManager();
    initLayout(initConfiguration);
};

initialize();
