/*
 * Copyright 2024 by Avid Technology, Inc.
 */

export const getIdFromResourece = (resource) => resource.replace('/chats/', '').replace('/messages', '');

const prepareIdsToRemove = (ids, value) => {
    const filteredValues = ids
        ? value.filter(({ resource }) => ids.includes(getIdFromResourece(resource)))
        : value;
    return filteredValues.map(({ id }) => id);
};

export default prepareIdsToRemove;
