/*
 * Copyright 2021, 2023 by Avid Technology, Inc.
 */

import keyCodes from '../../../keyCodes';

class Navigation {
    constructor(props) {
        this.button = props.button;
        this.list = props.dropdown.querySelector('ul');
        this.activeOption = null;
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onButtonKeyDown = this.onButtonKeyDown.bind(this);
        this.registerEvents();
    }

    registerEvents() {
        this.button.addEventListener('keydown', this.onButtonKeyDown);
        this.list.addEventListener('keydown', this.onKeyDown);
    }

    unregisterEvents() {
        this.button.removeEventListener('keydown', this.onButtonKeyDown);
        this.list.removeEventListener('keydown', this.onKeyDown);
    }

    onButtonKeyDown(event) {
        const { code } = event;

        if (code === keyCodes.ARROW_DOWN) {
            event.preventDefault();

            this.activeOption = this.list.children[0];
            this.activeOption.focus();
        }
    }

    onKeyDown(event) {
        const { code } = event;

        if (code === keyCodes.ARROW_DOWN) {
            event.preventDefault();

            this.nextValue();
        }

        if (code === keyCodes.ARROW_UP) {
            event.preventDefault();

            this.previousValue();
        }
    }

    previousValue() {
        const previousOption = this.activeOption.previousSibling;

        if (previousOption) {
            this.activeOption = previousOption;
            this.activeOption.focus();
        }
    }

    nextValue() {
        const nextOption = this.activeOption.nextSibling;

        if (nextOption) {
            this.activeOption = nextOption;
            this.activeOption.focus();
        }
    }
}

export default Navigation;
