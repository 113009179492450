/*
 * Copyright 2023 by Avid Technology, Inc.
 */
import StorageManager from '../main/StorageManager';
import { currentLayoutDataKey, sortOrderKey } from './sessionStorageKeys';

class LayoutStorage extends StorageManager {
    constructor(storage) {
        super(currentLayoutDataKey, storage);
    }
}

class SortLayoutOrderStorage extends StorageManager {
    constructor(storage) {
        super(sortOrderKey, storage);
    }
}

const layoutStorage = new LayoutStorage();
const sortLayoutOrderStorage = new SortLayoutOrderStorage();

export { layoutStorage, sortLayoutOrderStorage };
