/*
 * Copyright 2018, 2020-2023, 2024 by Avid Technology, Inc.
 */

import updateUserSettings from './updateUserSettings';
import initGeneralSystemSettings from './initGeneralSystemSettings';
import initLabsSettings from './initLabsSettings';
import initUserSettings from './initUserSettings';
import localizationStorage from '../login/localization/storage';
import isAdminLoginCheck from '../login/admin/isAdminLoginCheck';
import logger from '../../../logger/logger';

export default (
    generalSettings,
    userSettings,
    labsSettings,
) => new Promise((resolve) => {
    initGeneralSystemSettings(generalSettings);
    initLabsSettings(labsSettings);

    if (isAdminLoginCheck()) {
        resolve(userSettings);
    }

    const loginLocale = localizationStorage.getLocale();

    if (loginLocale && (userSettings.locale !== loginLocale)) {
        localizationStorage.setLocale(loginLocale);

        const nextSettings = {
            ...userSettings,
            locale: loginLocale,
        };

        return updateUserSettings(nextSettings)
            .then((settings) => {
                resolve(settings);
            })
            .catch((error) => {
                localizationStorage.setLocale(userSettings.locale);
                logger.warn('User Settings update failed. Use previous settings. Error:', error);
                resolve(userSettings);
            });
    }

    localizationStorage.setLocale(userSettings.locale || loginLocale);
    resolve(userSettings);
}).then((userSetting) => {
    initUserSettings(userSetting, isAdminLoginCheck());
}).catch((error) => {
    logger.warn(`Initializing settings has failed with error: ${error}.`);
    AV.internal.generalSystemSettings = {};
    AV.internal.userSettings = {};
});
