/*
 * Copyright 2018, 2021-2022 by Avid Technology, Inc.
 */

import store from './store-settings';
import fetch from './fetch-settings';

/**
 * UserSettings class
 * Class provides base API for working with iam principals settings.
 * fetch - returns setting by kind and key.
 * store - stores setting by kind and key
 */
export default class UserSettings {
    /**
     * Returns setting value by kind and key.
     * @param {String} kind Setting`s kind.
     * @param {String} key Setting`s key.
     * @returns {Promise} Requested iam setting {entity: [...{value}]}
     */
    fetch(kind, key) {
        return fetch(kind, key);
    }

    /**
     * Stores setting by kind and key to iam.
     * @param {Object} value Setting`s value
     * @param {String} kind Setting`s kind.
     * @param {String} key Setting`s key.
     * @returns {Promise} Saved iam setting {entity: {value}}.
     */
    store(value, kind, key) {
        const masterRegion = window?.AV?.internal?.masterRegion;

        return store(value, kind, key, masterRegion);
    }
}
