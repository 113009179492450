/*
 * Copyright 2018, 2021-2023 by Avid Technology, Inc.
 */

import login, { kerberosLogin, oktaLogin } from '../form/login-call';
import onLoginResponse, { onOktaLoginResponse } from './onLoginResponse';
import { loginTypes } from '../constants';

export default ({
    type, data, isAdministrator, links,
}) => {
    if (type === loginTypes.DEFAULT) {
        return login({
            username: data.username,
            password: data.password,
            link: links[type],
            isAdminLogin: isAdministrator,
        }).then((response) => onLoginResponse({ response, isAdministrator }));
    }

    if (type === loginTypes.KERBEROS) {
        return kerberosLogin({
            link: links[type],
            isAdminLogin: isAdministrator,
        }).then((response) => onLoginResponse({ response, isAdministrator }));
    }

    if (type === loginTypes.OKTA || type === loginTypes.OPEN_ID) {
        return oktaLogin({
            link: links[type],
            isAdminLogin: isAdministrator,
        }).then((response) => onOktaLoginResponse({ response }));
    }

    return Promise.reject();
};
