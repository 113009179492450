/*
 * Copyright 2024 by Avid Technology, Inc.
 */
import { Providers } from '@microsoft/mgt-element';
import logger from '../../../../../logger/logger';

const getToken = async () => {
    const token = await Providers.globalProvider.getAccessToken();

    if (!token) {
        logger.error('[MSChatProvider] [getToken] No token was returned');

        return null;
    }

    return token;
};

export default getToken;
