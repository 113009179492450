/*
 * Copyright 2020-2021, 2022 by Avid Technology, Inc.
 */

import isAdminLoginCheck from '../admin/isAdminLoginCheck';
import LanguageStorage from './LanguageStorage';

const storage = new LanguageStorage({ isAdmin: isAdminLoginCheck() });

export default storage;
